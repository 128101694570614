import React, { useEffect, useState } from "react";
import Header from "../components/Header";
import HeroSection from "../components/Hero";
import SectionTwo from "../components/section2";
import GoalSection from "../components/GoalSection";
import EventsComponent from "../components/EventsComponent";
import SupportSection from "../components/Support";
import GudaianceSection from "../components/Guadiance";
import Footer from "../components/Footer";
import axios from "axios";
import Toast from "../components/Toast";

export default function Home() {
  // const [data, setData] = useState(null);
  // const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [toastMessage, setToastMessage] = useState("");

  const showToast = (message) => {
    setToastMessage(message);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        // setLoading(true);
        await axios.get("https://rccg-t45u.onrender.com/members");
        //  setData(response.data);
        showToast("Page loaded successfully");
      } catch (err) {
        setError(err.message);
        showToast("Error loading some resources");
      } finally {
        // setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <div className="overflow-hidden">
      <Header />
      <HeroSection />
      <SectionTwo />
      <GoalSection />
      <EventsComponent />
      <SupportSection />
      <GudaianceSection />
      <Footer />
      <Toast
        message={toastMessage}
        customStyle={error ? "bg-red-500" : "bg-green-500"}
        onClose={() => {
          setToastMessage("");
          setError("");
        }}
      />
    </div>
  );
}
