import React from "react";
import OnlineServiceButton from "./OnlineServiceButton";
import Container from "./Container";

const MissionStatement = () => {
  return (
    <Container>
      <div className="max-w-4xl justify-center items-center mx-auto w-full lg:w-[70%] py-8 bg-white">
        <div className="space-y-6 text-left md:text-center text-gray-700 leading-relaxed">
          <div data-aos="fade-up" className="text-md sm:text-lg">
            Our goal is to make heaven and raise generals in faith, take many
            along to heaven, and be a blessing to our generation.
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="200"
            className="text-md sm:text-lg"
          >
            Through the word of God to those who are hungry, to satisfy their
            longing souls, nourish man with the living word to turn loose the
            power of God in man to become a blessing to his generation.
          </div>

          <div
            data-aos="fade-up"
            data-aos-delay="400"
            className="text-md sm:text-lg"
          >
            Our passion is to possess kingdoms and raise influential godly
            leaders, generals in faith and kingdom power. To create an
            atmosphere where mercy turns as many people we come in contact with
            into a blessing for their generation.
          </div>
        </div>

        <div className="mt-10 flex flex-col sm:flex-row justify-center items-center space-y-6 sm:space-y-0 sm:space-x-8 text-indigo-700">
          <p data-aos="fade-up" className="font-semibold text-lg text-center">
            Join Us Every
          </p>
          <div className="hidden sm:block w-px h-12 bg-indigo-700"></div>
          <div className="flex items-center">
            <div className="text-center">
              <p
                data-aos="fade-up"
                data-aos-delay="100"
                className="font-bold text-xl"
              >
                WEDNESDAY
              </p>
              <p data-aos="fade-up" data-aos-delay="200" className="text-black">
                ONLINE
              </p>
            </div>
          </div>
          <div className="hidden sm:block w-px h-12 bg-indigo-700"></div>
          <div className="flex items-center">
            <div className="text-center">
              <p
                data-aos="fade-up"
                data-aos-delay="100"
                className="font-bold text-xl"
              >
                SUNDAYS
              </p>
              <p data-aos="fade-up" data-aos-delay="200" className="text-black">
                10:00 AM
              </p>
            </div>
          </div>
        </div>

        <OnlineServiceButton />
      </div>
    </Container>
  );
};

export default MissionStatement;
