import React from "react";
import backgroundImage from "./../image/people.png";
import Container from "./Container";

const Hopeless = () => {
  return (
    <div
      className="relative flex items-center justify-center md:text-center mt-[6rem] h-[45vh] bg-cover bg-center"
      style={{
        backgroundImage: `url(${backgroundImage})`,
      }}
    >
      <div className="absolute inset-0 bg-[#2E3795] opacity-70"></div>
      <div className="absolute inset-0 bg-black opacity-80"></div>
      <Container>
        <div className="relative flex flex-col items-center text-white z-10">
          <h1
            className="text-4xl md:text-6xl font-bold mb-4"
            // data-aos="zoom-in"
          >
            HOPELESSNESS IS DEJECTED.
          </h1>
        </div>
      </Container>
    </div>
  );
};

export default Hopeless;
